import type { AppProps } from "next/app";

import Layout from "../components/layout/Layout";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-toastify/dist/ReactToastify.css";
import { ChakraProvider } from "@chakra-ui/react";
import store from "../store/index";
import { Provider } from "react-redux";
import "../styles/globals.css";
import { Garuda } from "garudaa";

Garuda.init({
  projectId: "655dca710b63927d3c52f418",
  host: "https://garuda-api.becknprotocol.io",
  globalConfigFlags: {
    disableNetworkPayload: true,
  },
});

Garuda.identify({
  email: "ujjwal.tiwari@eminds.ai",
  name: "Ujjwal",
  uId: "",
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider>
      <Provider store={store}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    </ChakraProvider>
  );
}

export default MyApp;
