import React, { useEffect, useState } from "react";
import BottomModal from "../BottomModal";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Settings from "./Settings";
import { useRouter } from "next/router";

import { useLanguage } from "../../hooks/useLanguage";
import Qrcode from "../qrCode/Qrcode";
import Button from "../button/Button";

const cartIconBlackList = [
  "/orderConfirmation",
  "/orderDetails",
  "/trackOrder",
  "/feedback",
  "/orderHistory",
  "/",
  "/mobileOtp",
  "/cart",
  "/checkoutPage",
  "/paymentMode",
];

const backIconList = ["/"];
const bottomModalIconList = ["/orderDetails"];

const homeIconBlackList = [
  "/orderHistory",
  "/",
  "/homePage",
  "/mobileOtp",
  "/checkoutPage",
  "/paymentMode",
  "/orderConfirmation",
  "/orderDetails",
];

const storeHeaderBlackList = [
  "/checkoutPage",
  "/orderHistory",
  "/orderDetails",
  "/cart",
  "/homePage",
  "/orderConfirmation",
  "feedback",
  "/",
  "/mobileOtp",
  "/paymentMode",
  "/search",
];
const headerValues = {
  "/checkoutPage": "Checkout",
  "/orderHistory": "Order History",
  "/orderDetails": "Booking Details",
  "/mobileOtp": "Sign In",
  "/search": "Travel Packages",
  "/cart": "Cart",
  "/paymentMode": "Select Payment Method",
  feedback: "Feedback",
};

const headerValuesFrench = {
  "/checkoutPage": "Facturation et Livraison",
  "/orderHistory": "Historique des Commandes",
  "/orderDetails": "Détails de la Commande",
  "/mobileOtp": "Se Connecter",
  "/cart": "Panier",
  "/paymentMode": "Sélectionner la Méthode de Paiement",
  feedback: "Retour d'Information",
};

const topHeaderBlackList: string[] = [];

const bottomHeaderBlackList = ["/", "/orderConfirmation"];

const menuIconWhiteList = ["/homePage"];

const languageIconWhiteList = ["/homePage", "/", "/mobileOtp"];

const getHeaderTitleForPage = (
  name: string,
  logo: string,
  pathName: string,
  locale: string | undefined
) => {
  const values = locale === "en" ? headerValues : headerValuesFrench;
  switch (true) {
    case storeHeaderBlackList.includes(pathName):
      return (
        <Text fontSize={"17px"} fontWeight={400}>
          {values[pathName]}
        </Text>
      );
    default:
      return (
        <Box width={"260px"} className="md:hidden ml-2  flex gap-1 my-2">
          <Text
            margin={"0 auto"}
            textAlign={"center"}
            fontSize={"17px"}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
          >
            {name}
          </Text>
        </Box>
      );
  }
};

export interface TopHeaderProps {
  handleMenuClick?: () => void;
}

const TopHeader: React.FC<TopHeaderProps> = ({ handleMenuClick }) => {
  const [isMenuModalOpen, setMenuModalOpen] = useState(false);
  const { t, locale, query } = useLanguage();
  const router = useRouter();

  return (
    <>
      <div className="h-7 w-full bg-[#efefef] fixed z-[9999]">
        <div className="flex items-center h-full px-5">
          <div>
            <Image src="/images/appLogo.svg" alt="App logo" />
          </div>
          <div className="flex gap-4 ml-auto">
            {languageIconWhiteList.includes(router.pathname) && <Settings />}

            {menuIconWhiteList.includes(router.pathname) && (
              <Image
                onClick={() => setMenuModalOpen(true)}
                className="block"
                src="/images/3-dots.svg"
                alt="menu icon"
              />
            )}

            {!homeIconBlackList.includes(router.pathname) && (
              <Image
                w={"88%"}
                onClick={() => {
                  let user = localStorage.getItem("userPhone") as string;
                  localStorage.clear();
                  localStorage.setItem("userPhone", user);
                  router.push(`/homePage?lang=${query?.lang}`);
                }}
                src="/images/Home_icon.svg"
                alt="home Icon"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const getLocalStorage = (item: string) => {
  if (typeof window !== "undefined" && window.localStorage) {
    return localStorage.getItem(item);
  } else {
    return "";
  }
};

const BottomHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [optionTags, setOptionTags] = useState<any>();
  const { locale, query } = useLanguage();
  const router = useRouter();

  const selectionPageUrl = process.env.NEXT_PUBLIC_SELECTION_PAGE_URL;
  const retailAppUrl = process.env.NEXT_PUBLIC_RETAIL_APP_URL;
  const storedHeaderText = getLocalStorage("selectCardHeaderText");
  const orderObjectUrl = useSelector(
    (state: {
      orderObjectUrl: { orderObjectUrl: string; isFlowCityOfParis: boolean };
    }) => state.orderObjectUrl.orderObjectUrl
  );
  const isFlowCityOfParis = useSelector(
    (state: {
      orderObjectUrl: { orderObjectUrl: string; isFlowCityOfParis: boolean };
    }) => state.orderObjectUrl.isFlowCityOfParis
  );

  useEffect(() => {
    setOptionTags(JSON.parse(localStorage.getItem("optionTags") as string));
  }, []);
  const { t } = useLanguage();
  return (
    <header className="md:fixed left-0 right-0 mb-4 top-0 md:bg-palette-fill shadow-sm pt-4 z-[1000] app_header_b fixed mt-7 z-[99] bg-[#fff]">
      <div className="flex flex-col md:px-4">
        <div className="relative flex items-center justify-between py-4 md:order-2 md:mt-2">
          <div className="flex items-center gap-4">
            {!backIconList.includes(router.pathname) && (
              <div onClick={() => router.back()}>
                <Image src="/images/Back.svg" alt="Back icon" />
              </div>
            )}
          </div>

          {getHeaderTitleForPage(
            storedHeaderText as string,
            optionTags?.logo,
            router.pathname,
            query?.lang ? query.lang : "en"
          )}
          <div className="flex gap-4">
            {!cartIconBlackList.includes(router.pathname)}
            {bottomModalIconList.includes(router.pathname) &&
              orderObjectUrl && (
                <Box onClick={onOpen}>
                  <Image
                    src="/images/BottomModalIcon.svg"
                    alt="BottomModalIcon icon"
                  />
                  <BottomModal
                    isOpen={isOpen}
                    onClose={onClose}
                    modalHeader={t("scanQR")}
                  >
                    <Box p={"0px 24px"}>
                      <Box textAlign={"center"} fontSize={"15px"}>
                        <Text>{t("scanthisQR")}</Text>
                        <Text mb={"20px"}>
                          {t("toImportthisorderanotherapp")}
                        </Text>
                      </Box>

                      <Qrcode
                        value={
                          isFlowCityOfParis
                            ? `${selectionPageUrl}??external_url=${orderObjectUrl}`
                            : `${retailAppUrl}/?lang=en&external_url=${orderObjectUrl}`
                        }
                      />

                      <Flex
                        align="center"
                        pt={"20px"}
                        w={"70%"}
                        margin={"0 auto"}
                      >
                        <Divider />
                        <Text padding="2" fontSize={"12px"}>
                          {t("or")}
                        </Text>
                        <Divider />
                      </Flex>
                      <Text pb={"20px"} fontSize={"12px"} textAlign={"center"}>
                        {t("clicktheShopbuttontobuyitemsforthistrip")}
                      </Text>
                      <Button
                        buttonText={t("proceedToPay")}
                        background={"rgba(var(--color-primary))"}
                        color={"rgba(var(--text-color))"}
                        isDisabled={false}
                        handleOnClick={() => {
                          if (window && orderObjectUrl)
                            window.location.href = isFlowCityOfParis
                              ? `${selectionPageUrl}??external_url=${orderObjectUrl}`
                              : `${retailAppUrl}/?lang=en&external_url=${orderObjectUrl}`;
                        }}
                      />
                      <Flex
                        pt={"20px"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Image pr={"20px"} src="/images/btmLogo1.png" />
                        <Image pr={"20px"} src="/images/kochiLogo.png" />
                        <Image src="/images/btmLogo3.png" />
                      </Flex>
                    </Box>
                  </BottomModal>
                </Box>
              )}
          </div>
        </div>
      </div>
    </header>
  );
};

const Header = () => {
  const router = useRouter();

  const renderTopHeader = !topHeaderBlackList.includes(router.pathname);
  const renderBottomHeader = !bottomHeaderBlackList.includes(router.pathname);

  return (
    <div>
      {renderTopHeader && <TopHeader />}
      {renderBottomHeader && <BottomHeader />}
    </div>
  );
};

export default Header;
